<!--========================
Author by: Sreng Veasna 
Created on: 14.08.2020
Modified:
Company: F2C V2
Description: 
=========================-->
<template>
  <v-main class="align-center">
    <v-container>
      <v-row justify="center">
        <v-col>
          <div class="d-flex justify-center">
            <router-view></router-view>
          </div>
        </v-col>
      </v-row>
    </v-container>
    <SpinnerOverlay />
  </v-main>
</template>

<script>
import SpinnerOverlay from "../../components/SpinnerOverlay";

export default {
  name: "AuthContainer",
  components: {
    SpinnerOverlay,
  },
};
</script>